import { useQueries, useQuery } from "@tanstack/react-query";
import _ from "lodash";
import { ApiGateway, FieldManagement, PolicyAdmin, useApiGateway } from "app/apiGateway";
import { PoliciesAcresQueryResult } from "./models/policies-acres-query-result";
import {
  PoliciesCropUnitsQueryResult,
  PoliciesPlanetWatchersConfidenceQueryResult,
} from "./models/policies-crop-unit-query-result";
import { PoliciesFarmsFieldsQueryResult } from "./models/policies-farms-fields-query-result";
import { PoliciesFarmsQueryResult } from "./models/policies-farms-query-result";
import { PoliciesFarmsShapesQueryResult } from "./models/policies-farms-shapes-query-result";
import { PoliciesProducerQueryResult } from "./models/policies-producer-query-result";
import { PoliciesUnitFarmsFieldsQueryResult } from "./models/policies-unit-farms-fields-query-result";
import { PoliciesQueryResult } from "./models/policies-query-result";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import { PoliciesCoveragesAcreageTypesResult } from "./models/policies-coverages-acreage-types-result";
import { PoliciesCoveragesUnitsAreaClassesResult } from "./models/policies-coverages-area-classes-result";
import { PoliciesCoveragesUnitsSkipRowRulesResult } from "./models/policies-coverages-skip-row-rules-result";
import { PoliciesFarmDeleteCommand } from "./models/policies-farm-delete-command";
import { usePoliciesFarmDeleteMutation } from "./policiesCommands";
import { PoliciesCoveragesUnitStructureQueryResult } from "./models/policies-coverages-unitstructure-query-result";
import { CoverageAcreageReportingQueryResult } from "./models/policies-coverages-acreagereporting-detail";

export const usePoliciesQuery = (policyId: number | undefined) => {
  const apiGateway = useApiGateway();
  const queryPolicyId = policyId ?? 0;

  const { data, isFetching } = useQuery<PoliciesQueryResult>({
    queryKey: ["policiesQuery", queryPolicyId],
    queryFn: async () =>
      await apiGateway.get(`policies/${queryPolicyId}`, FieldManagement),
    staleTime: 300 * 1000, //300 seconds...5minutes
    enabled: !!queryPolicyId,
  });

  return {
    policy: data,
    isPolicyFetching: isFetching,
  };
};

export const usePoliciesFarmsQuery = (
  policyId?: number,
  onSuccess?: (data: PoliciesFarmsQueryResult[]) => void
) => {
  const apiGateway = useApiGateway();
  const queryPolicyID = policyId ?? 0;
  const plantingSeason = useAppStateStore((s) => s.plantingSeason);

  const query = useQuery<PoliciesFarmsQueryResult[]>({
    queryKey: ["policiesFarmsQuery", queryPolicyID, plantingSeason],
    queryFn: async () =>
      await apiGateway.get(
        `policies/${queryPolicyID}/farms?plantingSeason=${plantingSeason}`,
        FieldManagement
      ),
    staleTime: 300 * 1000, //300 seconds...5minutes
    enabled: !!queryPolicyID,
    onSuccess: onSuccess,
  });

  return {
    farms: query.data,
    isFarmsFetching: query.isFetching,
    ...query,
  };
};

export const usePoliciesFarmsFieldsQuery = (
  policyId: number | undefined,
  farmId: number | undefined
) => {
  const apiGateway = useApiGateway();
  const queryPolicyId = policyId ?? 0;
  const queryFarmId = farmId ?? 0;
  const plantingSeason = useAppStateStore((s) => s.plantingSeason);

  const { data, isFetching } = useQuery<PoliciesFarmsFieldsQueryResult[]>({
    queryKey: [
      "policiesFarmsFieldsQuery",
      queryPolicyId,
      queryFarmId,
      plantingSeason,
    ],
    queryFn: getFarmsFields(apiGateway, policyId, queryFarmId, plantingSeason),
    staleTime: Infinity, //never consider this to be stale.
    enabled: !!queryPolicyId && !!queryFarmId && !!plantingSeason,
  });

  return {
    farmsFields: data,
    isFarmsFieldsFetching: isFetching,
  };
};

export const usePoliciesFarmsFieldsQueries = (
  policyId: number | undefined,
  farmIds: number[]
) => {
  const apiGateway = useApiGateway();
  const queryPolicyId = policyId ?? 0;
  const plantingSeason = useAppStateStore((s) => s.plantingSeason);

  return useQueries({
    queries: farmIds?.map((farm) => ({
      queryKey: [
        "policiesFarmsFieldsQuery",
        queryPolicyId,
        farm,
        plantingSeason,
      ],
      queryFn: getFarmsFields(apiGateway, policyId, farm, plantingSeason),
      staleTime: Infinity,
      enabled: !!queryPolicyId,
    })),
  });
};

function getFarmsFields(
  apiGateway: ApiGateway,
  policyId: number | undefined,
  farm: number,
  plantingSeason: string | undefined
): () => Promise<any> {
  return async () =>
    await apiGateway.get(
      `policies/${policyId}/farms/${farm}/fields?plantingSeason=${plantingSeason}`,
      FieldManagement
    );
}

export const usePoliciesCropUnitsQuery = (policyId: number | undefined) => {
  const apiGateway = useApiGateway();
  const queryPolicyId = policyId ?? 0;
  const plantingSeason = useAppStateStore((s) => s.plantingSeason);
  const queryEnabled = !!queryPolicyId && !!plantingSeason;

  const { data, isFetching, isFetched } =
    useQuery<PoliciesCropUnitsQueryResult>({
      queryKey: ["policiesCropUnitsQuery", queryPolicyId, plantingSeason],
      queryFn: async () =>
        await apiGateway.get(
          `policies/${queryPolicyId}/crop-units?plantingSeason=${plantingSeason}`,
          FieldManagement
        ),
      staleTime: Infinity,
      enabled: queryEnabled,
    });

  return {
    cropUnits: data,
    isCropUnitsFetching: isFetching,
    isCropUnitsFetched: isFetched,
  };
};

export const usePoliciesProducerQuery = (policyId: number | undefined) => {
  const apiGateway = useApiGateway();
  const queryPolicyId = policyId ?? 0;

  const { data, isFetching } = useQuery<PoliciesProducerQueryResult>({
    queryKey: ["policiesProducerQuery", queryPolicyId],
    queryFn: async () =>
      await apiGateway.get(
        `policies/${queryPolicyId}/producers`,
        FieldManagement
      ),
    staleTime: 300 * 1000, //300 seconds...5minutes
    enabled: !!queryPolicyId,
  });

  return {
    producer: data,
    isProducerFetching: isFetching,
  };
};

export const usePoliciesAcresQuery = (policyId: number | undefined) => {
  const apiGateway = useApiGateway();
  const queryPolicyId = policyId ?? 0;
  const plantingSeason = useAppStateStore((s) => s.plantingSeason);

  const { data, isFetching } = useQuery<PoliciesAcresQueryResult[]>({
    queryKey: ["policiesAcresQuery", queryPolicyId, plantingSeason],
    queryFn: async () =>
      await apiGateway.get(
        `policies/${queryPolicyId}/acres?plantingSeason=${plantingSeason}`,
        FieldManagement
      ),
    staleTime: 300 * 1000, //300 seconds...5minutes
    enabled: !!queryPolicyId,
  });

  return {
    acres: data,
    isAcresFetching: isFetching,
  };
};

export const usePoliciesFarmsShapesQuery = (
  policyId: number | undefined,
  farmId: number | undefined
) => {
  const apiGateway = useApiGateway();
  const queryPolicyId = policyId ?? 0;
  const queryFarmId = farmId ?? 0;

  const { data, isFetching } = useQuery<PoliciesFarmsShapesQueryResult[]>({
    queryKey: ["policiesFarmsShapesQuery", queryPolicyId, queryFarmId],
    queryFn: async () =>
      await apiGateway.get(
        `policies/${queryPolicyId}/farms/${queryFarmId}/shapes`,
        FieldManagement
      ),
    staleTime: 300 * 1000, //300 seconds...5minutes
    enabled: !!queryPolicyId && !!queryFarmId,
  });

  return {
    farmsShapes: data,
    isFarmsShapesFetching: isFetching,
  };
};

export const usePoliciesCoveragesAcreageTypesQuery = (
  policyId: number | undefined,
  coverageId: number | undefined
) => {
  const apiGateway = useApiGateway();
  const queryPolicyId = policyId ?? 0;
  const queryCoverageId = coverageId ?? 0;

  const { data, isFetching } = useQuery<PoliciesCoveragesAcreageTypesResult[]>({
    queryKey: [
      "policiesCoveragesAcreageTypesQuery",
      queryPolicyId,
      queryCoverageId,
    ],
    queryFn: async () =>
      await apiGateway.get(
        `policies/${queryPolicyId}/coverages/${queryCoverageId}/acreage-types`,
        FieldManagement
      ),
    staleTime: 300 * 1000, //300 seconds...5minutes
    enabled: !!queryPolicyId && !!queryCoverageId,
  });

  return {
    coverageAcreageTypes: data,
    isCoveragesAcreageTypesFetching: isFetching,
  };
};

export const usePoliciesCoveragesUnitsAreaClassesQuery = (
  policyId: number | undefined,
  coverageId: number | undefined,
  unitId: number | undefined
) => {
  const apiGateway = useApiGateway();
  const queryPolicyId = policyId ?? 0;
  const queryCoverageId = coverageId ?? 0;
  const queryUnitId = unitId ?? 0;

  const { data, isFetching } = useQuery<
    PoliciesCoveragesUnitsAreaClassesResult[]
  >({
    queryKey: [
      "policiesCoveragesUnitsAreaClassesQuery",
      queryPolicyId,
      queryCoverageId,
      queryUnitId,
    ],
    queryFn: async () =>
      await apiGateway.get(
        `policies/${queryPolicyId}/coverages/${queryCoverageId}/units/${queryUnitId}/area-classes`,
        FieldManagement
      ),
    staleTime: 300 * 1000, //300 seconds...5minutes
    enabled: !!queryPolicyId && !!queryCoverageId && !!queryUnitId,
  });

  return {
    coverageUnitsAreaClasses: data,
    isCoveragesUnitsAreaClassesFetching: isFetching,
  };
};

export const usePoliciesCoveragesUnitsSkipRowRulesQuery = (
  policyId: number | undefined,
  coverageId: number | undefined,
  unitId: number | undefined
) => {
  const apiGateway = useApiGateway();
  const queryPolicyId = policyId ?? 0;
  const queryCoverageId = coverageId ?? 0;
  const queryUnitId = unitId ?? 0;

  const { data, isFetching } =
    useQuery<PoliciesCoveragesUnitsSkipRowRulesResult>({
      queryKey: [
        "policiesCoveragesUnitsSkipRowRulesQuery",
        queryPolicyId,
        queryCoverageId,
        queryUnitId,
      ],
      queryFn: async () =>
        await apiGateway.get(
          `policies/${queryPolicyId}/coverages/${queryCoverageId}/units/${queryUnitId}/skip-row-rules`,
          FieldManagement
        ),
      staleTime: 300 * 1000, //300 seconds...5minutes
      enabled: !!queryPolicyId && !!queryCoverageId && !!queryUnitId,
    });

  return {
    coverageUnitsSkipRowRules: data,
    isCoveragesUnitsSkipRowRulesFetching: isFetching,
  };
};

export const usePoliciesUnitsFieldsQuery = (
  policyId: number,
  unitId: number | undefined
) => {
  const apiGateway = useApiGateway();
  const plantingSeason = useAppStateStore((s) => s.plantingSeason);
  const { data, isFetching, isFetched } = useQuery<
    PoliciesUnitFarmsFieldsQueryResult[]
  >({
    queryKey: ["policiesUnitsFieldsQuery", policyId, unitId],
    queryFn: getUnitFields(apiGateway, policyId, unitId, plantingSeason),
    enabled: !!plantingSeason && !!unitId,
  });

  return {
    unitFields: data,
    isUnitFieldsFetching: isFetching,
    isUnitFieldsFetched: isFetched,
  };
};


//UnitStructure
export const usePoliciesCoveragesUnitStructureQuery = (
  policyId: number
  
) => {
  const apiGateway = useApiGateway();
  const { data, isFetching, isFetched, error, refetch} = useQuery<
    PoliciesCoveragesUnitStructureQueryResult[]
  >({
    queryKey: ["coveragesUnitStructureQuery", policyId],
    queryFn: getCoveragesUnitStructure(apiGateway, policyId),
    enabled: !!policyId
  });

  return {
    coveragesUnitStructure: data,
    isCoverageUnitStructureFetching: isFetching,
    isCoverageUnitStructureFetched: isFetched,
    error : error,
    refetch 
  };
};

//coverage Acreage Reporting details
export const usePoliciesCoveragesAcreageReportingDetail = (
  policyId: number

) => {
  const apiGateway = useApiGateway();
  const { data, isFetched, error } = useQuery<
    CoverageAcreageReportingQueryResult[]
  >({
    queryKey: ["coveragesAcreageReportingDetailQuery", policyId],
    queryFn: getCoveragesAcreageReportingDetail(apiGateway, policyId),
    enabled: !!policyId
  });

  return {
    coveragesAcreageReportingDetail: data,
    isAcreageReportingDetailFetched: isFetched,
    coveragesAcreageReportingDetailerror: error
  };
};

export const usePoliciesPlanetWatchersConfidenceQuery = (
  policyId: number | undefined
) => {
  const apiGateway = useApiGateway();
  const queryPolicyId = policyId ?? 0;
  const plantingSeason = useAppStateStore((s) => s.plantingSeason);

  const { data, isFetching } =
    useQuery<PoliciesPlanetWatchersConfidenceQueryResult>({
      queryKey: [
        "policiesPlanetWatchersConfidenceQuery",
        queryPolicyId,
        plantingSeason,
      ],
      queryFn: async () =>
        await apiGateway.get(
          `v1.0/policies/${queryPolicyId}/planetwatchers/confidence?plantingSeason=${plantingSeason}`,
          FieldManagement
        ),
      staleTime: Infinity,
      enabled: !!queryPolicyId && !!plantingSeason,
    });

  return {
    planetWatchersConfidence: data,
    isPlanetWatchersConfidenceFetching: isFetching,
  };
};

export const useDeleteFarm = (
  onSuccess: () => void,
  onError: () => void
): {
  deleteFarm: (policyId: number, farmId: number) => void;
} => {
  const { mutateFarmDelete } = usePoliciesFarmDeleteMutation();

  const deleteFarm = (policyId: number, farmId: number) => {
    const command: PoliciesFarmDeleteCommand = {
      policyId: policyId,
      farmId: farmId,
    };

    mutateFarmDelete(command, {
      onSuccess: onSuccess,
      onError: onError,
    });
  };
  return { deleteFarm: deleteFarm };
};

function getUnitFields(
  apiGateway: ApiGateway,
  policyId: number,
  unitId: number | undefined,
  plantingSeason: string | undefined
): () => Promise<any> {
  return async () =>
    await apiGateway.get(
      `policies/${policyId}/units/${unitId}/farm-fields?plantingSeason=${plantingSeason}`,
      FieldManagement
    );
};

function getCoveragesUnitStructure(
  apiGateway: ApiGateway,
  policyId: number
): () => Promise<any> {
  return async () =>
    await apiGateway.get(
      `policies/${policyId}/coverages`,
      PolicyAdmin
    );
}

function getCoveragesAcreageReportingDetail(
  apiGateway: ApiGateway,
  policyId: number
): () => Promise<any> {
  return async () =>
    await apiGateway.get(
      `policies/${policyId}/coverage-acreagereporting-detail`,
      PolicyAdmin
    );
}
