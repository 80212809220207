import { Button, Flex, Icon, Typography } from "@proag/sprout";
import React from "react";
import { useState } from "react";
import { WARNINGMESSAGES, BUTTON_TEXT } from "../../../../app/common/constants";
import styles from "./producers-planted-acres-review-units-sidebar-card.module.scss";
import { UnitStructureReviewSidebarSheet } from "./unit-structure-review-sidebar-sheet";

export const ReviewManageUnitStructureCard: React.FC = ({ }) => {

  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  function handleClose() {
    return setShow(false);
  }

  return (
    <>
      <div data-testid="review-manage-unit-structure-card" className= {`${"h-100"} ${styles.attentionCard}`}>
        <br /> <br />
        <Flex direction="column" justifyContent="center">
          <Icon iconName="WarningFilled" size="xl" color="#F19F49" />
          <Typography className="title-md">Attention Needed</Typography>
          <br /> <br />
          <div className={styles.attentionWarning}>{WARNINGMESSAGES.MUS_QUALIFICATION_ALERT}</div>
          <br />
        </Flex>

        <Flex justifyContent="center">
          <Button
            variant="warning-solid"
            rightIcon="ChevronRight"
            size="md"
            onClick={handleShow}
          >
            {BUTTON_TEXT.ReviewUnitStructure}
          </Button>
          <UnitStructureReviewSidebarSheet show={show} handleClose={handleClose} isCollapse={show}></UnitStructureReviewSidebarSheet>
        </Flex>

      </div>

    </>
  );
};
