import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import { FieldManagement, useApiGateway } from "app/apiGateway";

import { PoliciesAllFarmsShapesQueryResult } from "./models/policies-all-farms-shapes-query-result";
import { PoliciesFarmsAcreageQueryResult } from "./models/policies-farms-acreage-query-result";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import { PoliciesReportAcreageStatusResult } from "./models/policies-report-acreage-status-result";
import { PoliciesAcresObserveQueryResult } from "./models/policies-acres-observed-query-result";
import { PoliciesAgencyAgentProducerQueryResult } from "./models/policies-agency-agent-producer-query-result";
import { PoliciesCropUnitsQueryResult } from "features/managed-acres";

export const usePoliciesAllFarmsShapesQuery = (
  policyId: number | undefined
) => {
  const apiGateway = useApiGateway();
  const queryPolicyId = policyId ?? -1;

  const { data, isFetching } = useQuery<PoliciesAllFarmsShapesQueryResult[]>({
    queryKey: ["policiesAllFarmsShapesQuery", queryPolicyId],
    queryFn: async () =>
      await apiGateway.get(`policies/${queryPolicyId}/shapes`, FieldManagement),
    staleTime: 300 * 1000, //300 seconds...5minutes
  });

  return {
    farmsShapes: data,
    isAllFarmsShapesFetching: isFetching,
  };
};

export const usePoliciesFarmsAcreageQuery = (policyId: number | undefined) => {
  const apiGateway = useApiGateway();
  const queryPolicyId = policyId ?? -1;
  const plantingSeason = useAppStateStore((s) => s.plantingSeason);

  const { data, isFetching } = useQuery<PoliciesFarmsAcreageQueryResult[]>({
    queryKey: ["policiesFarmsAcreageQuery", queryPolicyId, plantingSeason],
    queryFn: async () =>
      await apiGateway.get(
        `policies/${queryPolicyId}/farms/acreage?plantingSeason=${plantingSeason}`,
        FieldManagement
      ),
    staleTime: 300 * 1000, //300 seconds...5minutes
  });

  return {
    farms: data,
    isFarmsAcreageFetching: isFetching,
  };
};

export const usePoliciesFarmsAcreageStatus = (
  policyId: number | undefined,
  valid: boolean
) => {
  const apiGateway = useApiGateway();
  const plantingSeason = useAppStateStore((s) => s.plantingSeason);

  const { data, isFetching } = useQuery<PoliciesReportAcreageStatusResult>({
    queryKey: ["policiesFarmsAcreageStatusQuery", policyId, plantingSeason],
    queryFn: async () =>
      await apiGateway.get(
        `policies/${policyId}/acreage-validation-status/?plantingSeason=${plantingSeason}`,
        FieldManagement
      ),
    refetchInterval: 10 * 1000, // call every ten seconds untill we get a success
    enabled: valid,
  });

  return {
    acreageStatusData: data,
    isFarmsAcreageFetching: isFetching,
  };
};

export const usePoliciesAcresObserveQuery = () => {
  const apiGateway = useApiGateway();
  const queryPolicyId = useAppStateStore((s) => s.policyId);
  const plantingSeason = useAppStateStore((s) => s.plantingSeason);

  const { data, isFetching, refetch } =
    useQuery<PoliciesAcresObserveQueryResult>({
      queryKey: ["policiesAcresObserveQuery", queryPolicyId, plantingSeason],
      queryFn: async () =>
        await apiGateway.get(
          `policies/${queryPolicyId}/observe-acres?plantingSeason=${plantingSeason}`,
          FieldManagement
        ),
      staleTime: 300 * 1000, //300 seconds...5minutes
    });

  return {
    acresObserved: data,
    isAcresObserveFetching: isFetching,
    refetchAcresObserve: refetch,
  };
};

export const usePoliciesAgencyAgentProducerQuery = (
  policyId: number | undefined
) => {
  const apiGateway = useApiGateway();
  const queryPolicyId = policyId ?? -1;

  const { data, isFetching, refetch, isFetched } =
    useQuery<PoliciesAgencyAgentProducerQueryResult>({
      queryKey: ["PoliciesAgencyAgentProducerQuery", queryPolicyId],
      queryFn: async () =>
        await apiGateway.get(
          `policies/${policyId}/agency-agent-producer`,
          FieldManagement
        ),
    });

  return {
    policyInfo: data,
    isPolicyInfoFetching: isFetching,
    fetchPolicyInfo: refetch,
    policyInfoIsFetched: isFetched,
  };
};

export const usePoliciesCropUnitsReviewQuery = (
  policyId: number | undefined
) => {
  const apiGateway = useApiGateway();
  const queryPolicyId = policyId ?? -1;
  const plantingSeason = useAppStateStore((s) => s.plantingSeason);

  const { data, isFetching, isFetched } =
    useQuery<PoliciesCropUnitsQueryResult>({
      queryKey: ["policiesCropUnitsReviewQuery", queryPolicyId, plantingSeason],
      queryFn: async () =>
        await apiGateway.get(
          `policies/${queryPolicyId}/crop-units-review?plantingSeason=${plantingSeason}`,
          FieldManagement
        ),
      staleTime: Infinity,
    });

  return {
    cropUnits: data,
    isCropUnitsFetching: isFetching,
    isCropUnitsFetched: isFetched,
  };
};
