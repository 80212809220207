import { useOktaAuth } from "@okta/okta-react";
import { useMutation } from "@tanstack/react-query";
import { ApiGateway, FieldManagement, PolicyAdmin, useApiGateway } from "app/apiGateway";
import { getApiGatewayUrl } from "app/apiGateway/utils/apiUtils";
import { useAppStateStore } from "app/state-management/use-app-state-store";
import {
  ImportACRSIResult,
  ImportPlanetWatchersResult,
  PoliciesFarmsFieldCreateCommand,
  PoliciesFarmsFieldDeleteCommand,
  PoliciesFarmsFieldRecord,
  PoliciesFarmsFieldUpdateCommand,
  PoliciesFarmsFieldsUpdateCommand,
} from "features/managed-acres";
import { PoliciesFarmDeleteCommand } from "./models/policies-farm-delete-command";
import { PoliciesUnitUpdateCommand } from "./models/policies-unit-update-command";
import { PoliciesCoveragesUnitStructureUpdate } from "./models/policies-coverages-unitstructure-update-command";

// this hook is being abandoned in favor of the autosave.
// lets remove it soon.
export const usePoliciesFarmsFieldsUpdate = () => {
  const apiGateway = useApiGateway();

  const { mutate, isLoading, isSuccess, isError } = useMutation({
    mutationKey: ["PoliciesFarmsFieldsUpdate"],
    mutationFn: async (cmd: PoliciesFarmsFieldsUpdateCommand) => {
      await api_PoliciesFarmsFieldsUpdate(apiGateway, cmd);
    },
  });

  return {
    mutateFields: mutate,
    isFieldsSaving: isLoading,
    isFieldsSaveSuccess: isSuccess,
    isFieldsSaveError: isError,
  };
};

export const usePoliciesAcreageReportPublish = (
  policyId: number | undefined
) => {
  const apiGateway = useApiGateway();
  const publishPolicyId = policyId ?? -1;
  const plantingSeason = useAppStateStore((s) => s.plantingSeason);

  const { isLoading, mutate } = useMutation({
    mutationKey: [
      "policiesAcreageReportPublish",
      publishPolicyId,
      plantingSeason,
    ],
    mutationFn: async () =>
      await apiGateway.post(
        `policies/${publishPolicyId}/acreagereport/publish?plantingSeason=${plantingSeason}`,
        FieldManagement,
        { policyId: policyId }
      ),
  });

  return {
    isPublishingAcreageReport: isLoading,
    mutate,
  };
};

export const usePolicyMutationImportFromACRSI = (
  policyId: number | undefined,
  onImportSuccess: () => void,
  onImportFail: () => void
) => {
  const apiGateway = useApiGateway();
  const plantingSeason = useAppStateStore((s) => s.plantingSeason);

  const queryPolicyId = policyId ?? -1;

  const { isLoading, mutate } = useMutation<ImportACRSIResult>({
    mutationKey: ["importACRSI", policyId, plantingSeason],
    mutationFn: async () =>
      await apiGateway.post(
        `policies/${queryPolicyId}/import/acrsi?plantingSeason=${plantingSeason}`,
        FieldManagement,
        { policyId: policyId }
      ),
    onSuccess: onImportSuccess,
    onError: onImportFail,
  });

  return {
    isFetchingFromAcrsi: isLoading,
    mutate,
  };
};

export const usePoliciesFarmsFieldUpdateMutation = () => {
  const apiGateway = useApiGateway();

  const { authState, oktaAuth } = useOktaAuth();
  const _token = authState?.accessToken?.accessToken;

  const { mutate } = useMutation({
    mutationKey: ["PoliciesFarmsFieldUpdate"],
    mutationFn: async (cmd: PoliciesFarmsFieldUpdateCommand) => {
      const path = `v1.0/policies/${cmd.policyId}/farms/${cmd.farmId}/fields/${cmd.record.acreageReportFieldId}`;
      //apiGateway.put( path, FieldManagement, cmd.record);

      // this is an experiment to directly call the api,
      // without going through the "apiGateway"
      // SOMETHING is wrong with our implementation
      // and its causing Chrome to be unable to read the error response
      // in DevTools, in the even the api call fails.
      // this makes is so that you cannot read the correlationId
      // and therefore will struggle finding your error in cloudwatch.
      // ----

      const uri = getApiGatewayUrl(window) + path;
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          applicationId: FieldManagement,
          Authorization: `Bearer ${_token}`,
        },
        body: JSON.stringify(cmd.record),
      };
      fetch(uri, requestOptions);
    },
  });

  return {
    mutateFieldsUpdate: mutate,
  };
};

export const usePoliciesFarmsFieldCreateMutation = () => {
  const apiGateway = useApiGateway();

  const { mutate, isLoading, isSuccess, data } = useMutation({
    mutationKey: ["PoliciesFarmsFieldCreate"],
    mutationFn: async (cmd: PoliciesFarmsFieldCreateCommand) => {
      return apiGateway.post(
        `v1.0/policies/${cmd.policyId}/farms/${cmd.record.farmId}/fields`,
        FieldManagement,
        cmd.record
      );
    },
  });

  return {
    mutateFieldsCreate: mutate,
    isMutateFieldsCreateLoading: isLoading,
    isMutateFieldsCreateSuccess: isSuccess,
    mutateFieldsCreateResult: data,
  };
};
export const usePoliciesFarmsFieldDeleteMutation = () => {
  const apiGateway = useApiGateway();

  const { mutate } = useMutation({
    mutationKey: ["PoliciesFarmsFieldDelete"],
    mutationFn: async (cmd: PoliciesFarmsFieldDeleteCommand) => {
      apiGateway.delete(
        `v1.0/policies/${cmd.policyId}/farms/${cmd.farmId}/fields/${cmd.fieldId}`,
        FieldManagement
      );
    },
  });

  return {
    mutateFieldsDelete: mutate,
  };
};

export const usePoliciesUnitUpdateMutation = (onSaveSuccess: () => void) => {
  const apiGateway = useApiGateway();

  const { mutate } = useMutation({
    mutationKey: ["PoliciesUnitUpdate"],
    mutationFn: async (cmd: PoliciesUnitUpdateCommand) => {
      return apiGateway.put(
        `v1.0/policies/${cmd.policyId}/unit/${cmd.record.unitId}`,
        FieldManagement,
        cmd.record
      );
    },
    onSuccess: onSaveSuccess,
  });

  return {
    mutateUnitUpdate: mutate,
  };
};
/***********************
 * HELPER API WRAPPERS *
 * *********************/

const api_PoliciesFarmsFieldsUpdate = async (
  apiGateway: ApiGateway,
  fieldData: PoliciesFarmsFieldsUpdateCommand
) => {
  const cmdPolicyId = fieldData.policyId;
  const cmdFarmId = fieldData.farmId;
  await apiGateway.post(
    `policies/${cmdPolicyId}/farms/${cmdFarmId}/fields`,
    FieldManagement,
    fieldData.records
    /*     {
      plantedAcres: fieldData.plantedAcres ? fieldData.plantedAcres : null,
      plantedDate: fieldData.plantedDate,
      preventedPlant: fieldData.preventedPlant,
      share: fieldData.share,
      subField: fieldData.subField,
      coverageId: fieldData.coverageId,
      unitId: fieldData.unitId,
      uninsuredReasonCode: fieldData.uninsuredReasonCode,
    } */
  );
};

//TODO: update with correct API call
// This is our own API that I'm making now.
// It may have to change later to accomodate different params
export const usePolicyMutationImportFromPlanetWatchers = (
  policyId: number | undefined,
  onImportSuccess: () => void,
  onImportFail: () => void
) => {
  const apiGateway = useApiGateway();

  const plantingSeason = useAppStateStore((s) => s.plantingSeason);
  const queryPolicyId = policyId ?? -2;

  const { isLoading, mutate } = useMutation<ImportPlanetWatchersResult>({
    mutationKey: ["importACRSI", policyId, plantingSeason],
    mutationFn: async () =>
      await apiGateway.post(
        `v1.0/policies/${queryPolicyId}/import/planetwatchers?plantingSeason=${plantingSeason}`,
        FieldManagement,
        { policyId: policyId }
      ),
    onSuccess: onImportSuccess,
    onError: onImportFail,
  });

  return {
    isFetchingFromPlanetWatchers: isLoading,
    mutate,
  };
};

export const useFieldBulkUpdate = (
  onSuccess?: (data: any) => void,
  policyId?: number
) => {
  const apiGateway = useApiGateway();

  return useMutation({
    mutationKey: ["fieldsBulkEdit"],
    mutationFn: (fields?: PoliciesFarmsFieldRecord[]) => {
      return apiGateway.patch(
        `policies/${policyId}/farms/fields`,
        FieldManagement,
        fields ?? []
      );
    },
    onSuccess: onSuccess,
  });
};

export const exportedForTesting = {
  api_PoliciesFarmsFieldsUpdate,
};

export const usePoliciesFarmDeleteMutation = () => {
  const apiGateway = useApiGateway();

  const { mutate } = useMutation({
    mutationKey: ["PoliciesFarmDelete"],
    mutationFn: async (cmd: PoliciesFarmDeleteCommand) => {
      apiGateway.delete(
        `v1.0/policies/${cmd.policyId}/farms/${cmd.farmId}`,
        FieldManagement
      );
    },
  });

  return {
    mutateFarmDelete: mutate,
  };
};


export const usePoliciesCoveragesUnitStructurePost = (
  
  policyId: number | undefined,
  onSuccess: () => void,
  onError: () => void) => {
  const apiGateway = useApiGateway();
  const { mutate, isLoading, isSuccess, data } = useMutation({
    mutationKey: ["PoliciesCoveragesUnitStructureUpdate"],
    mutationFn: async (cmd: PoliciesCoveragesUnitStructureUpdate[]) => {
      return apiGateway.post(
        `policies/${policyId}/coverageUnitsStructureOptionOverride`,
        PolicyAdmin,
        cmd
      );
    },
    onSuccess : onSuccess,
    onError : onError
  });

  return {
    mutate,
    isMutateUnitStructureLoading: isLoading,
    isMutateUnitStructureSuccess: isSuccess,
    mutateUnitStructureResult: data,
  };
};
